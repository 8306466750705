import styled from "@emotion/styled";
import React from "react";
import theme from "../theme";

export interface ParagraphProps {
  dropCap?: boolean;
  // Can be used to render Prismic RichText content
  innerHTML?: string;
  indent?: boolean;
}

type StyledPProps = Pick<ParagraphProps, "dropCap" | "indent">;

const StyledParagraph = styled.p<StyledPProps>`
  font-size: ${theme.fontSizes.md};
  line-height: ${theme.lineHeights.base};
  text-align: left;
  letter-spacing: ${theme.letterSpacings.wide};

  margin-block-start: 0;
  margin-block-end: 0;
  margin: ${theme.space[2]} auto;

  li {
    margin-bottom: ${theme.space[2]};
  }

  & {
    ${(props) =>
      props.dropCap
        ? `&:first-letter {
          float: left;
          font-size: ${theme.fontSizes["4xl"]};
          margin-right: ${theme.space[2]};
          font-family: ${theme.fonts.heading};
          font-style: italic;
        }`
        : null}
    ${(props) =>
      props.indent && !props.dropCap ? `text-indent: ${theme.space[2]}` : null}
  }
`;

export const Paragraph: React.FC<ParagraphProps> = (props) => {
  const { dropCap, innerHTML, children, indent } = props;

  const innerHTMLProp = innerHTML
    ? { dangerouslySetInnerHTML: { __html: innerHTML } }
    : {};

  return (
    <StyledParagraph dropCap={dropCap} {...innerHTMLProp} indent={indent}>
      {children}
    </StyledParagraph>
  );
};
