import styled from "@emotion/styled";
import React from "react";
import theme from "../theme";
import { mediaQuery } from "../theme/breakpoints";

const StyledPageTitle = styled.div`
  margin-top: ${theme.space[6]};
  margin-bottom: ${theme.space[6]};

  h2 {
    color: ${theme.colors.black};
    margin-block-start: 0;
    margin-block-end: 0;
    font-family: ${theme.fonts.heading};
    font-size: ${theme.fontSizes["3xl"]};

    ${mediaQuery(theme.breakpoints.sm)} {
      font-size: ${theme.fontSizes["4xl"]};
    }

    padding-left: ${theme.space[4]};
  }
`;

export const PageTitle: React.FC = ({ children }) => (
  <StyledPageTitle>
    <h2>{children}</h2>
  </StyledPageTitle>
);
