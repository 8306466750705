import React from "react";
import Page from "../components/Page";
import { PageTitle } from "../components/PageTitle";
import { Paragraph } from "../components/Paragraph";
import SEO from "../components/SEO";
import theme from "../theme";

const NotFoundPage = () => (
  <Page>
    <SEO title="404: Not found" />
    <PageTitle>Not found</PageTitle>
    <div
      style={{
        margin: "0 auto",
        padding: `${theme.space[1]} ${theme.space[4]}`
      }}
    >
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <Paragraph>This page doesn't exist on the site.</Paragraph>
    </div>
  </Page>
);

export default NotFoundPage;
